import { default as _slugECXUVcGof3Meta } from "/home/mycarrental/mcr-nuxt/pages/_slug.vue?macro=true";
import { default as _404zUklvN9lJQMeta } from "/home/mycarrental/mcr-nuxt/pages/404.vue?macro=true";
import { default as _91id_93FvUvCFWtdgMeta } from "/home/mycarrental/mcr-nuxt/pages/blog/[id].vue?macro=true";
import { default as indexEDA6T5pjUZMeta } from "/home/mycarrental/mcr-nuxt/pages/blog/index.vue?macro=true";
import { default as BookingVaE4I89iiLMeta } from "/home/mycarrental/mcr-nuxt/pages/Booking.vue?macro=true";
import { default as contactsFMiNQjUOBDMeta } from "/home/mycarrental/mcr-nuxt/pages/contacts.vue?macro=true";
import { default as corporateut5MDhO38LMeta } from "/home/mycarrental/mcr-nuxt/pages/corporate.vue?macro=true";
import { default as franchiseE8vhM7eg5rMeta } from "/home/mycarrental/mcr-nuxt/pages/franchise.vue?macro=true";
import { default as indexbUpUHjmstGMeta } from "/home/mycarrental/mcr-nuxt/pages/index.vue?macro=true";
import { default as partnerkaFlKqcSgLxfMeta } from "/home/mycarrental/mcr-nuxt/pages/partnerka.vue?macro=true";
import { default as _91slug_93G8ffeepdYAMeta } from "/home/mycarrental/mcr-nuxt/pages/regions__/[slug].vue?macro=true";
import { default as _91id_93KP4mURXYM6Meta } from "/home/mycarrental/mcr-nuxt/pages/reservation/[id].vue?macro=true";
import { default as termsIlm1OaSR2NMeta } from "/home/mycarrental/mcr-nuxt/pages/terms.vue?macro=true";
import { default as user_45agreementv6swr6GhxrMeta } from "/home/mycarrental/mcr-nuxt/pages/user-agreement.vue?macro=true";
import { default as _slugwffj03MhPjMeta } from "~/pages/_slug.vue?macro=true";
import { default as _91id_93xlpFJ7eH29Meta } from "~/pages/blog/[id].vue?macro=true";
import { default as terms6ldi9JYIvUMeta } from "~/pages/terms.vue?macro=true";
import { default as user_45agreementC5NiffWMRKMeta } from "~/pages/user-agreement.vue?macro=true";
import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
export default [
  {
    name: _slugECXUVcGof3Meta?.name ?? "_slug",
    path: _slugECXUVcGof3Meta?.path ?? "/_slug",
    meta: _slugECXUVcGof3Meta || {},
    alias: _slugECXUVcGof3Meta?.alias || [],
    redirect: _slugECXUVcGof3Meta?.redirect,
    component: () => import("/home/mycarrental/mcr-nuxt/pages/_slug.vue").then(m => m.default || m)
  },
  {
    name: _404zUklvN9lJQMeta?.name ?? "404",
    path: _404zUklvN9lJQMeta?.path ?? "/404",
    meta: _404zUklvN9lJQMeta || {},
    alias: _404zUklvN9lJQMeta?.alias || [],
    redirect: _404zUklvN9lJQMeta?.redirect,
    component: () => import("/home/mycarrental/mcr-nuxt/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _91id_93FvUvCFWtdgMeta?.name ?? "blog-id",
    path: _91id_93FvUvCFWtdgMeta?.path ?? "/blog/:id()",
    meta: _91id_93FvUvCFWtdgMeta || {},
    alias: _91id_93FvUvCFWtdgMeta?.alias || [],
    redirect: _91id_93FvUvCFWtdgMeta?.redirect,
    component: () => import("/home/mycarrental/mcr-nuxt/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: indexEDA6T5pjUZMeta?.name ?? "blog",
    path: indexEDA6T5pjUZMeta?.path ?? "/blog",
    meta: indexEDA6T5pjUZMeta || {},
    alias: indexEDA6T5pjUZMeta?.alias || [],
    redirect: indexEDA6T5pjUZMeta?.redirect,
    component: () => import("/home/mycarrental/mcr-nuxt/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: BookingVaE4I89iiLMeta?.name ?? "Booking",
    path: BookingVaE4I89iiLMeta?.path ?? "/Booking",
    meta: BookingVaE4I89iiLMeta || {},
    alias: BookingVaE4I89iiLMeta?.alias || [],
    redirect: BookingVaE4I89iiLMeta?.redirect,
    component: () => import("/home/mycarrental/mcr-nuxt/pages/Booking.vue").then(m => m.default || m)
  },
  {
    name: contactsFMiNQjUOBDMeta?.name ?? "contacts",
    path: contactsFMiNQjUOBDMeta?.path ?? "/contacts",
    meta: contactsFMiNQjUOBDMeta || {},
    alias: contactsFMiNQjUOBDMeta?.alias || [],
    redirect: contactsFMiNQjUOBDMeta?.redirect,
    component: () => import("/home/mycarrental/mcr-nuxt/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: corporateut5MDhO38LMeta?.name ?? "corporate",
    path: corporateut5MDhO38LMeta?.path ?? "/corporate",
    meta: corporateut5MDhO38LMeta || {},
    alias: corporateut5MDhO38LMeta?.alias || [],
    redirect: corporateut5MDhO38LMeta?.redirect,
    component: () => import("/home/mycarrental/mcr-nuxt/pages/corporate.vue").then(m => m.default || m)
  },
  {
    name: franchiseE8vhM7eg5rMeta?.name ?? "franchise",
    path: franchiseE8vhM7eg5rMeta?.path ?? "/franchise",
    meta: franchiseE8vhM7eg5rMeta || {},
    alias: franchiseE8vhM7eg5rMeta?.alias || [],
    redirect: franchiseE8vhM7eg5rMeta?.redirect,
    component: () => import("/home/mycarrental/mcr-nuxt/pages/franchise.vue").then(m => m.default || m)
  },
  {
    name: indexbUpUHjmstGMeta?.name ?? "index",
    path: indexbUpUHjmstGMeta?.path ?? "/",
    meta: indexbUpUHjmstGMeta || {},
    alias: indexbUpUHjmstGMeta?.alias || [],
    redirect: indexbUpUHjmstGMeta?.redirect,
    component: () => import("/home/mycarrental/mcr-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: partnerkaFlKqcSgLxfMeta?.name ?? "partnerka",
    path: partnerkaFlKqcSgLxfMeta?.path ?? "/partnerka",
    meta: partnerkaFlKqcSgLxfMeta || {},
    alias: partnerkaFlKqcSgLxfMeta?.alias || [],
    redirect: partnerkaFlKqcSgLxfMeta?.redirect,
    component: () => import("/home/mycarrental/mcr-nuxt/pages/partnerka.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93G8ffeepdYAMeta?.name ?? "regions__-slug",
    path: _91slug_93G8ffeepdYAMeta?.path ?? "/regions__/:slug()",
    meta: _91slug_93G8ffeepdYAMeta || {},
    alias: _91slug_93G8ffeepdYAMeta?.alias || [],
    redirect: _91slug_93G8ffeepdYAMeta?.redirect,
    component: () => import("/home/mycarrental/mcr-nuxt/pages/regions__/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93KP4mURXYM6Meta?.name ?? "reservation-id",
    path: _91id_93KP4mURXYM6Meta?.path ?? "/reservation/:id()",
    meta: _91id_93KP4mURXYM6Meta || {},
    alias: _91id_93KP4mURXYM6Meta?.alias || [],
    redirect: _91id_93KP4mURXYM6Meta?.redirect,
    component: () => import("/home/mycarrental/mcr-nuxt/pages/reservation/[id].vue").then(m => m.default || m)
  },
  {
    name: termsIlm1OaSR2NMeta?.name ?? "terms",
    path: termsIlm1OaSR2NMeta?.path ?? "/terms",
    meta: termsIlm1OaSR2NMeta || {},
    alias: termsIlm1OaSR2NMeta?.alias || [],
    redirect: termsIlm1OaSR2NMeta?.redirect,
    component: () => import("/home/mycarrental/mcr-nuxt/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: user_45agreementv6swr6GhxrMeta?.name ?? "user-agreement",
    path: user_45agreementv6swr6GhxrMeta?.path ?? "/user-agreement",
    meta: user_45agreementv6swr6GhxrMeta || {},
    alias: user_45agreementv6swr6GhxrMeta?.alias || [],
    redirect: user_45agreementv6swr6GhxrMeta?.redirect,
    component: () => import("/home/mycarrental/mcr-nuxt/pages/user-agreement.vue").then(m => m.default || m)
  },
  {
    name: _slugwffj03MhPjMeta?.name ?? "city",
    path: _slugwffj03MhPjMeta?.path ?? "/:slug",
    meta: _slugwffj03MhPjMeta || {},
    alias: _slugwffj03MhPjMeta?.alias || [],
    redirect: _slugwffj03MhPjMeta?.redirect,
    component: () => import("~/pages/_slug.vue").then(m => m.default || m)
  },
  {
    name: _slugwffj03MhPjMeta?.name ?? "region",
    path: _slugwffj03MhPjMeta?.path ?? "/regions/:slug",
    meta: _slugwffj03MhPjMeta || {},
    alias: _slugwffj03MhPjMeta?.alias || [],
    redirect: _slugwffj03MhPjMeta?.redirect,
    component: () => import("~/pages/_slug.vue").then(m => m.default || m)
  },
  {
    name: _91id_93xlpFJ7eH29Meta?.name ?? "news_page",
    path: _91id_93xlpFJ7eH29Meta?.path ?? "/regions/:city/:slug",
    meta: _91id_93xlpFJ7eH29Meta || {},
    alias: _91id_93xlpFJ7eH29Meta?.alias || [],
    redirect: _91id_93xlpFJ7eH29Meta?.redirect,
    component: () => import("~/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: terms6ldi9JYIvUMeta?.name ?? "terms",
    path: terms6ldi9JYIvUMeta?.path ?? "/terms",
    meta: terms6ldi9JYIvUMeta || {},
    alias: terms6ldi9JYIvUMeta?.alias || [],
    redirect: terms6ldi9JYIvUMeta?.redirect,
    component: () => import("~/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: user_45agreementC5NiffWMRKMeta?.name ?? "user-agreement",
    path: user_45agreementC5NiffWMRKMeta?.path ?? "/user-agreement",
    meta: user_45agreementC5NiffWMRKMeta || {},
    alias: user_45agreementC5NiffWMRKMeta?.alias || [],
    redirect: user_45agreementC5NiffWMRKMeta?.redirect,
    component: () => import("~/pages/user-agreement.vue").then(m => m.default || m)
  },
  {
    name: indexp9V2p5fxycMeta?.name ?? "index",
    path: indexp9V2p5fxycMeta?.path ?? "/",
    meta: indexp9V2p5fxycMeta || {},
    alias: indexp9V2p5fxycMeta?.alias || [],
    redirect: indexp9V2p5fxycMeta?.redirect,
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  }
]